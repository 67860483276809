import * as React from 'react';

const NumberButton = (props) => {
	var styles = {};
	if (props.state == 1)
		styles.backgroundColor = "lightgreen";
	if (props.state == 2)
		styles.backgroundColor = "lightblue";
	if (props.state == 3)
		styles.backgroundColor = "lightcoral";
	const cb = () => {
		if (props.result != 0) return;
		if (props.state == 0)
			props.addcandidateNum(props.id);
		if (props.state == 2 || props.state == 3)
			props.removecandidateNum(props.id);
	}
	return (
		<button style={styles} className="number" onClick={cb}>
			{props.id}
		</button>
	);
}


export { NumberButton };