import { StarMath } from './StarMath'
import * as React from 'react';
import './App.css';

function App() {
  return (
    <StarMath />
  );
}

export default App;
