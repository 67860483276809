import * as React from 'react';
import { MathUtils } from './MathUtils';
import { NumberButton } from './NumberButton';
import { DisplayStars } from './DisplayStars'

const StarMath = () => {
	let result = 0;
	const [remainingTime, setTime] = React.useState(15);
	const [unusedNum, setunusedNum] = React.useState(MathUtils.rangearray(1, 9));
	const [candiateNum, setCandidateNum] = React.useState([]);
	const [stars, SetStars] = React.useState(MathUtils.randomnum(1, 9));
	const addcandidateNum = a => setCandidateNum([...candiateNum, a]);
	const removecandidateNum = a => setCandidateNum(candiateNum.filter(num => num !== a));
	const resetGame = () => {
		setTime(15);
		setunusedNum(MathUtils.rangearray(1, 9));
		setCandidateNum([]);
		SetStars(MathUtils.randomnum(1, 9));
	}

	if (MathUtils.sumOfArray(candiateNum) == stars) {
		let x = [...unusedNum];
		let y = x.filter(num => candiateNum.indexOf(num) < 0);
		setunusedNum(y);
		setCandidateNum([]);
		SetStars(MathUtils.getRandomElement(MathUtils.getAllPossibleStars(y)));
	}
	if (remainingTime == 0) result = 2;
	if (unusedNum.length == 0) result = 1;

	if (result == 0) setTimeout(() => setTime(remainingTime - 1), 1000);

	return (
		<div className="game">
			<div className="help">Click on one or more numbers whose sum is equal to the number of stars</div>
			<div className="body">
				<div className="left">
					{result == 0 ? <DisplayStars star={stars} /> : <div className="game-done"><div className="message">You {result == 1 ? "Won" : "lost"}</div><button onClick={resetGame}>Play Again</button></div>}
				</div>
				<div className="right">
					{MathUtils.rangearray(1, 9).map(id => {
						let state = 0;
						if (unusedNum.indexOf(id) < 0)
							state = 1;
						if (candiateNum.indexOf(id) >= 0)
							state = MathUtils.sumOfArray(candiateNum) > stars ? 3 : 2;
						return <NumberButton key={id} id={id} stars={stars} state={state} result={result} removecandidateNum={removecandidateNum} addcandidateNum={addcandidateNum} />
					}
					)}
				</div>
			</div>
			<div className="timer">Time Remaining: {remainingTime}s</div>
		</div >
	);
}

export { StarMath };