class MathUtils {
	static randomnum = (a, b) => {
		let r = Math.random();
		r = a + Math.floor(r * (b - a + 1));
		return r;
	}

	static sumOfArray = (a) => {
		var ct = 0;
		a.map(n => ct += n);
		return ct;
	}

	static getAllPossibleStars = (a) => {
		let arr = [...a];
		for (let i = 0; i < a.length - 1; i++) {
			for (let j = i + 1; j < a.length; j++) {
				let sum = a[i] + a[j];
				if (sum <= 9 && arr.lastIndexOf(sum) < 0)
					arr.push(sum);
			}
		}
		return arr;
	}

	static getRandomElement = a => a[this.randomnum(0, a.length - 1)];

	static rangearray = (a, b) => {
		let arr = [];
		for (let i = a; i <= b; i++) arr.push(i);
		return arr;
	}

}

export { MathUtils };